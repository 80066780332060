import axios from "../api/axios";
import statuses from "statuses";

const fetchCampaigns = async ({
  offset,
  byAllUsers,
  limit,
  filters,
  sort,
  order,
  query,
} = {}) => {
  try {
    const response = await axios.get("/campaigns", {
      params: { offset, byAllUsers, limit, filters, sort, order, query },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchStrategies = async (data) => {
  try {
    const response = await axios.post("/strategies", data);

    if (response.status !== 200) {
      return {
        success: false,
        isMoneyInsufficient: response.status === 402,
        message: response.message,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      isMoneyInsufficient: error.response?.status === 402,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetCampaignPreview = async (data) => {
  try {
    const response = await axios.post("/campaign-preview", data);

    if (response.status !== 200) {
      return {
        success: false,
        isMoneyInsufficient: response.status === 402,
        message: response.message,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      isMoneyInsufficient: error.response?.status === 402,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchOptedOutCandidates = async (data) => {
  try {
    const response = await axios.post("/contacts/opted-out", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetTempCampaign = async (crm) => {
  try {
    const response = await axios.get("/campaigns-temp", {
      params: { crm },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchPostTempCampaign = async (data) => {
  try {
    const response = await axios.post("/campaigns-temp", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCreateCampaign = async ({ campaign }) => {
  try {
    const response = await axios.post("/campaigns", campaign);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCampaignsStatistic = async () => {
  try {
    const response = await axios.get("/campaigns-statistic");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCampaignTokensCost = async (data) => {
  try {
    const response = await axios.post("/get-campaign-tokens-cost", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCampaign = async (campaignId) => {
  try {
    const response = await axios.get(`/campaigns/${campaignId}`);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCandidates = async ({
  campaignId,
  forReview,
  offset,
  query,
  sort,
  order,
  limit,
  filters,
}) => {
  try {
    const response = await axios.get(`/campaigns/${campaignId}/contacts`, {
      params: {
        forReview,
        offset,
        filters,
        limit: limit || "10",
        query: query || "",
        order: order || "",
        sort: sort || "",
      },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUpdateCampaignStatus = async ({ campaignId, status }) => {
  try {
    const response = await axios.post(`campaigns/${campaignId}/status`, {
      status,
    });

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCandidateMessages = async (campaignId, candidateId) => {
  try {
    const response = await axios.get(
      `/campaigns/${campaignId}/contact/${candidateId}/messages`
    );

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCandidatesSubscription = async (crm, emails) => {
  try {
    const response = await axios.post("/contacts/subscriptions", {
      crm,
      emails,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchPreviewTempMessage = async ({
  tempCampaignId,
  campaign,
  isRegenerate,
  attempt,
}) => {
  try {
    const response = await axios.post(
      `/campaigns-temp/${tempCampaignId}/message-preview`,
      {
        campaign,
        isRegenerate,
        attempt,
      }
    );

    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      isMoneyInsufficient: error.response?.status === 402,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchPreviewMessage = async ({
  campaignId,
  candidateId,
  regenerate,
  attempt,
}) => {
  try {
    const response = await axios.get(
      `/campaigns/${campaignId}/contact/${candidateId}/message/preview`,
      {
        params: {
          regenerate,
          attempt,
        },
      }
    );
    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    if (error.response?.status === 409) {
      return { inProgress: true, message: error.response?.message };
    }
    return {
      success: false,
      isMoneyInsufficient: error.response?.status === 402,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchUpdatePreviewMessage = async ({ campaignId, candidateId, data }) => {
  try {
    const response = await axios.put(
      `/campaigns/${campaignId}/contact/${candidateId}/message/preview`,
      data
    );

    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchGenerateAllMessagePreviews = async ({ campaignId }) => {
  try {
    const response = await axios.post(
      `campaigns/${campaignId}/messages/preview-generation/start`
    );

    if (response.status !== 202) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchStopGenerateMessagePreviews = async ({ campaignId }) => {
  try {
    const response = await axios.post(
      `campaigns/${campaignId}/messages/preview-generation/stop`
    );

    if (response.status !== 202) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchPreviewsGenerationStatus = async ({ campaignId }) => {
  try {
    const response = await axios.get(
      `campaigns/${campaignId}/messages/preview-generation/status`
    );

    if (response.status !== 200 && response.status !== 202) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchUpdateUser = async (data) => {
  try {
    const response = await axios.put("/account", data);

    if (response.status === 200) {
      return { user: response.data, success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUpdatePassword = async (data) => {
  try {
    const response = await axios.put("/account/change-password", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchLogin = async (data) => {
  try {
    const response = await axios.post("/login", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCheckInviteCode = async (code) => {
  try {
    const response = await axios.post("/register/check-invite-code", { code });
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchSignUp = async (data) => {
  try {
    const response = await axios.post("/register", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchSignUpOauth = async (data) => {
  console.log("data", data);
  try {
    const response = await axios.post("/register/oauth/google", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchContactUs = async (data) => {
  try {
    const response = await axios.post("/contact-us", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchAskResetPassword = async (data) => {
  try {
    const response = await axios.post("/recover/start", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUser = async () => {
  try {
    const response = await axios.get("/me");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetPostSignUpOnboarding = async () => {
  try {
    const response = await axios.get("/post-sign-up-onboarding");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchSetPostSignUpOnboarding = async (question, answer) => {
  try {
    const response = await axios.post("/post-sign-up-onboarding", {
      question,
      answer,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchAutoConfigureLog = async (data) => {
  try {
    const response = await axios.post("/auto-configure-log", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetPrepCampaigns = async () => {
  try {
    const response = await axios.get("/campaigns-prep");
    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const prepareResultMessage = (result) => {
  if (typeof result?.message === "string") {
    const statusCode = (/with (status code|error code|error)[:]? ([0-9]+)/.exec(
      result.message
    ) || [])[2];
    if (statusCode) {
      let statusDesc;
      try {
        statusDesc = statuses.message[statusCode];
      } catch {}
      result.message = statusDesc
        ? `Status: ${statusDesc}`
        : result.message
            .replace(/with (status code|error code|error)[:]? ([0-9]+)/, "")
            .trim();
    }
  }
  return result;
};

const fetchCreateIntegration = async (data) => {
  try {
    const response = await axios.post("/integrations", data);

    if (response.status !== 200) {
      return prepareResultMessage({
        success: false,
        message: response.message,
      });
    }

    return prepareResultMessage(response.data);
  } catch (error) {
    return prepareResultMessage({
      success: false,
      message: error.response?.data?.message || error.message,
    });
  }
};

const fetchGetIntegrations = async () => {
  try {
    const response = await axios.get("/integrations");

    if (response.status !== 200) {
      return prepareResultMessage({
        success: false,
        message: response.message,
      });
    }

    return prepareResultMessage(response.data);
  } catch (error) {
    return prepareResultMessage({
      success: false,
      message: error.response?.data?.message || error.message,
    });
  }
};

const fetchPrompts = async () => {
  try {
    const response = await axios.get("/prompts");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUpdatePrompt = async (data) => {
  try {
    const response = await axios.put("/prompts", data);

    if (response.status !== 200) {
      return {
        success: false,
        message:
          response.status === 402
            ? response.data?.message
            : response.statusText,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchSamples = async () => {
  try {
    const response = await axios.get("/samples");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    return {
      success: false,
      message: "Something wrong while trying to get samples",
    };
  }
};

const fetchCreateSample = async ({ name, fields }) => {
  try {
    const response = await axios.post("/samples", { name, fields });

    if (response.status !== 200) {
      return { success: false, message: response.statusText };
    }

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    return {
      success: false,
      message: "Something wrong while trying to create sample",
    };
  }
};

const fetchUpdateSample = async ({ id, name, fields }) => {
  try {
    const response = await axios.put(`/samples/${id}`, { name, fields });

    if (response.status !== 200) {
      return { success: false, message: response.statusText };
    }

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    return {
      success: false,
      message: "Something wrong while trying to update sample",
    };
  }
};

const fetchRemoveSample = async (sampleId) => {
  try {
    const response = await axios.delete(`/samples/${sampleId}`);

    if (response.status !== 200) {
      return { success: false, message: response.statusText };
    }

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    return {
      success: false,
      message: "Something wrong while trying to remove sample",
    };
  }
};

const fetchTestPrompt = async ({ sampleId, promptId, testParams }) => {
  try {
    const response = await axios.get(`/prompts/${promptId}/test/${sampleId}`, {
      params: testParams,
    });

    if (response.status !== 200) {
      return { success: false, message: response.statusText };
    }

    return response.data;
  } catch (error) {
    console.log(error.response?.data?.message || error.message);
    return {
      success: false,
      message:
        error.response?.status === 402
          ? "You do not have enough credits to perform this operation"
          : "Something wrong while trying to test prompt",
    };
  }
};

const fetchSetPromptActive = async (data) => {
  try {
    const response = await axios.put(
      `/prompts/set-active/${data.type}/${data.id}`,
      { crm: data.crm }
    );

    if (response.status !== 200) {
      return { success: false, message: response.statusText };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUpdateUserSignature = async (data) => {
  try {
    const response = await axios.put("/user-signature", data);

    if (response.status === 200) {
      return { user: response.data, success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchRefreshToken = async (token) => {
  try {
    const response = await axios.post("/refresh-token", {
      refreshToken: token,
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCompany = async () => {
  try {
    const response = await axios.get("/company");

    if (response.status === 200) {
      return { company: response.data.company, success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: "Something went wrong while fetching company",
    };
  }
};

const fetchUpdateCompany = async (data) => {
  try {
    const response = await axios.put("/company", data);

    if (response.status === 200) {
      return { company: response.data.company, success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: `Something went wrong while ${
        data.isNew ? "creating" : "updating"
      } company`,
    };
  }
};

const fetchChangeCandidateSubscribe = async ({ candidateId, action }) => {
  try {
    const response = await axios.get(
      `/candidate/${candidateId}/subscription/${action}`
    );

    if (response.status === 200) {
      return { success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: `Something went wrong while ${action}`,
    };
  }
};

const fetchHubspotFields = async () => {
  try {
    const response = await axios.get("/integrations/hubspot-fields");

    if (response.status === 200) {
      return { success: true, data: response.data };
    }
  } catch (error) {
    return {
      success: false,
      message: "Something went wrong while fetching HubSpot fields",
    };
  }
};

const fetchUpdateHubspotFields = async (data) => {
  try {
    const response = await axios.put("/integrations/hubspot-fields", data);

    if (response.status === 200) {
      return { success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: "Something went wrong while updating HubSpot fields",
    };
  }
};

const fetchUpdateOpenAiModels = async (data) => {
  try {
    const response = await axios.put("/integrations/openai-models", data);

    if (response.status === 200) {
      return { success: true };
    }
  } catch (error) {
    return {
      success: false,
      message: "Something went wrong while updating OpenAI models",
    };
  }
};

const fetchSendEmail = async (data) => {
  try {
    const response = await axios.post("/send-mail", data);

    if (response.status === 200) {
      return { success: true, message: response.data?.message };
    }
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "Something went wrong while sending email",
    };
  }
};

const fetchDisconnectIntegration = async (data) => {
  try {
    const response = await axios.post("/integrations/disconnect", data);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message || response.statusText,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message:
        error.response?.data?.message ||
        "Something went wrong while disconnect integration",
    };
  }
};

const fetchGptCallsStats = async (date) => {
  try {
    const response = await axios.get(
      "/gpt-calls-statistic",
      date ? { params: { date } } : {}
    );

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUsers = async ({
  offset,
  query,
  limit,
  filters,
  sort,
  order,
} = {}) => {
  try {
    const response = await axios.get("/users", {
      params: { offset, query, limit, filters, sort, order },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchSaveUser = async (data) => {
  try {
    const response = await axios.put("/user", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetPriceForRegisterUser = async () => {
  try {
    const response = await axios.get("/subscription-price");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUserActivity = async ({
  offset,
  query,
  limit,
  filters,
  sort,
  order,
} = {}) => {
  try {
    const response = await axios.get("/user-activity-report", {
      params: { offset, query, limit, filters, sort, order },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchNewUsers = async ({
  offset,
  query,
  limit,
  filters,
  sort,
  order,
} = {}) => {
  try {
    const response = await axios.get("/new-users-report", {
      params: { offset, query, limit, filters, sort, order },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCampaignsSummary = async ({
  offset,
  query,
  limit,
  filters,
  sort,
  order,
} = {}) => {
  try {
    const response = await axios.get("/campaigns-summary-report", {
      params: { offset, query, limit, filters, sort, order },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCampaignDetails = async ({
  offset,
  query,
  limit,
  filters,
  sort,
  order,
} = {}) => {
  try {
    const response = await axios.get("/campaign-details-report", {
      params: { offset, query, limit, filters, sort, order },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchUndeliverableEmails = async ({
  offset,
  query,
  limit,
  filters,
  sort,
  order,
} = {}) => {
  try {
    const response = await axios.get("/undeliverable-emails-report", {
      params: { offset, query, limit, filters, sort, order },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCreateInvite = async (data) => {
  try {
    const response = await axios.post("/invite-code", data);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message || response.statusText,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetNotifications = async (data) => {
  try {
    const response = await axios.get("/notifications", {
      params: data,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetNotificationsCount = async (data) => {
  try {
    const response = await axios.get("/notifications/count", {
      params: data,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchReadNotifications = async (data) => {
  try {
    const response = await axios.put("/notifications", data);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message || response.statusText,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchValidateCampaignGoal = async (data) => {
  try {
    const response = await axios.post("/validate-goal", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCompanies = async ({ offset, query, limit } = {}) => {
  try {
    const response = await axios.get("/companies", {
      params: { offset, query, limit },
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetFilters = async (data) => {
  try {
    const response = await axios.get("/filters", {
      params: data,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetCompanyFilters = async (data) => {
  try {
    const response = await axios.get("/company/filters", {
      params: data,
    });

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchResetPassword = async (data) => {
  try {
    const response = await axios.post("/recover/confirm", data);

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGetMinExtensionVersion = async () => {
  try {
    const response = await axios.get("min-extension-version");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchGenerateReplyForMessage = async ({ campaignId, id: messageId }) => {
  try {
    const response = await axios.get(
      `/campaigns/${campaignId}/messages/${messageId}/generated-reply`
    );
    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchSendReplyMessage = async ({ campaignId, id: messageId }, reply) => {
  try {
    const response = await axios.post(
      `/campaigns/${campaignId}/messages/${messageId}/reply`,
      { ...reply }
    );
    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchChangeCandidateStatus = async ({
  campaignId,
  candidateId,
  status,
}) => {
  try {
    const response = await axios.post(
      `/campaigns/${campaignId}/contacts/${candidateId}/status`,
      { status }
    );
    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchChangeContactSequenceStatus = async ({
  contactId,
  stepNum,
  status,
}) => {
  try {
    const response = await axios.post(
      `/contact/${contactId}/step/${stepNum}/status`,
      {
        status,
      }
    );
    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage:
        error.response?.data?.message ||
        error.message ||
        "Could not update a status",
    };
  }
};

const fetchGetContact = async (contactId) => {
  try {
    const response = await axios.get(`/contact/${contactId}`);
    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage:
        error.response?.data?.message ||
        error.message ||
        "Could not get a contact",
    };
  }
};

const fetchParseGoogleSheetsContacts = async (crmType, contacts) => {
  try {
    const response = await axios.post(`/contacts/google-sheets`, {
      crmType,
      contacts,
    });

    if (response.status !== 200) {
      return { success: false, errorMessage: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage:
        error.response?.data?.message ||
        error.message ||
        "Could not parse contacts",
    };
  }
};

const fetchSubscriptionPlans = async () => {
  try {
    const response = await axios.get("/register/dictionaries");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data.subscriptionPlans;
  } catch (error) {
    return {
      success: false,
      errorMessage: error.response?.data?.message || error.message,
    };
  }
};

const fetchOauthUserData = async (data) => {
  try {
    const response = await axios.post("/get-oauth-user-data", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      errorMessage:
        error.response?.data?.message ||
        error.message ||
        "Couldn't get user information from google",
    };
  }
};

const fetchInvoices = async () => {
  try {
    const response = await axios.get("/invoices");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchCreateSetupIntent = async () => {
  try {
    const response = await axios.post("/subscription/setup-intent");

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchChangeSubscriptionPlan = async (data) => {
  try {
    const response = await axios.put("/subscription/plan", data);

    if (response.status !== 200) {
      return {
        success: false,
        message: response.message,
        isConflict: response.status === 409,
      };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
      isConflict: error.response?.status === 409 || false,
    };
  }
};

const fetchUpdateBillingDetails = async (data) => {
  try {
    const response = await axios.post(
      "/subscription/update-billing-details",
      data
    );

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchVerifyEmail = async (data) => {
  try {
    const response = await axios.post("/confirm-email", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

const fetchSendVerificationCode = async (data) => {
  try {
    const response = await axios.post("/resend-code", data);

    if (response.status !== 200) {
      return { success: false, message: response.message };
    }

    return response.data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};

export {
  fetchLogin,
  fetchCheckInviteCode,
  fetchSignUp,
  fetchUser,
  fetchGetPostSignUpOnboarding,
  fetchSetPostSignUpOnboarding,
  fetchAutoConfigureLog,
  fetchGetPrepCampaigns,
  fetchCreateIntegration,
  fetchGetIntegrations,
  fetchUpdatePassword,
  fetchUpdateUser,
  fetchCampaigns,
  fetchCampaignsStatistic,
  fetchCampaignTokensCost,
  fetchCampaign,
  fetchCandidates,
  fetchGetTempCampaign,
  fetchPostTempCampaign,
  fetchCreateCampaign,
  fetchUpdateCampaignStatus,
  fetchPrompts,
  fetchUpdatePrompt,
  fetchSetPromptActive,
  fetchSamples,
  fetchCreateSample,
  fetchUpdateSample,
  fetchRemoveSample,
  fetchTestPrompt,
  fetchUpdateUserSignature,
  fetchCandidateMessages,
  fetchPreviewTempMessage,
  fetchPreviewMessage,
  fetchUpdatePreviewMessage,
  fetchGenerateAllMessagePreviews,
  fetchStopGenerateMessagePreviews,
  fetchPreviewsGenerationStatus,
  fetchStrategies,
  fetchGetCampaignPreview,
  fetchOptedOutCandidates,
  fetchContactUs,
  fetchRefreshToken,
  fetchCompany,
  fetchUpdateCompany,
  fetchChangeCandidateSubscribe,
  fetchHubspotFields,
  fetchUpdateHubspotFields,
  fetchUpdateOpenAiModels,
  fetchSendEmail,
  fetchDisconnectIntegration,
  fetchGptCallsStats,
  fetchUsers,
  fetchSaveUser,
  fetchGetPriceForRegisterUser,
  fetchCreateInvite,
  fetchGetNotifications,
  fetchReadNotifications,
  fetchGetNotificationsCount,
  fetchValidateCampaignGoal,
  fetchCompanies,
  fetchGetFilters,
  fetchGetCompanyFilters,
  fetchCandidatesSubscription,
  fetchAskResetPassword,
  fetchResetPassword,
  fetchGetMinExtensionVersion,
  fetchGenerateReplyForMessage,
  fetchSendReplyMessage,
  fetchChangeCandidateStatus,
  fetchChangeContactSequenceStatus,
  fetchGetContact,
  fetchParseGoogleSheetsContacts,
  fetchSubscriptionPlans,
  fetchUserActivity,
  fetchNewUsers,
  fetchCampaignsSummary,
  fetchCampaignDetails,
  fetchUndeliverableEmails,
  fetchOauthUserData,
  fetchSignUpOauth,
  fetchInvoices,
  fetchCreateSetupIntent,
  fetchChangeSubscriptionPlan,
  fetchUpdateBillingDetails,
  fetchVerifyEmail,
  fetchSendVerificationCode,
};
