import React from "react";
import styled from "styled-components";
import SuggestionPopup from "../components/SuggestionPopup";
import { sendMessageToExtension } from "../utils/postToExtension";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";

const SAMPLE_CLIENT_LIST_LINK =
  "https://docs.google.com/spreadsheets/d/1ix9Auz2P__FvFBmM4zBghNJl-owNvtcCDOmbF0V7aRg/edit?usp=sharing";

const Alert = () => {
  const userInfo = getUserFromLocalStorage();

  const closeIframe = () => {
    sendMessageToExtension({
      message: "close-iframe",
    });
  };

  const crmNotFoundMsg =
    "Please navigate to a browser tab with your CRM or spreadsheet to start a campaign";

  return (
    <SuggestionPopup
      imageSrc="/logo192.png"
      title="We can't find your contacts!"
      description={
        userInfo?.hasCreatedCampaign ? (
          crmNotFoundMsg
        ) : (
          <>
            {`${crmNotFoundMsg}. You can see a sample sheet`}{" "}
            <Link
              href={SAMPLE_CLIENT_LIST_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </Link>
          </>
        )
      }
      confirmBtnText="Got it!"
      onConfirm={closeIframe}
      removeCancel
      noOverlay
    />
  );
};

export default Alert;

const Link = styled.a`
  font-size: inherit;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.15s ease-out;
  color: #0091ae;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #007a8c;
    text-decoration: underline;
  }
`;
